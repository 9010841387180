html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root {
  height: 100%;
  display: flex;
  direction: column;
  width: 100vw;
}
